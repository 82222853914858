// import React, { useState, useContext } from "react";

// import { ThemeContext } from "../../utils/ThemeContext";
// import { ResumeContext } from "../../utils/ResumeContext";
// import LoadingSpinner from "../../components/UIElements/LoadingSpinner";
import "./MyExperience.css";
// import MyProjects from "./myProjects";

const MyExperience = (props) => {
  // const { theme } = useContext(ThemeContext);

  return (
    <div className="myCV-container">
      <h1 className="centered-content">MY EXPERIENCE</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut sem viverra
        aliquet eget sit amet tellus cras. Nec sagittis aliquam malesuada
        bibendum arcu vitae elementum curabitur. Venenatis cras sed felis eget
        velit aliquet sagittis id consectetur. Sit amet consectetur adipiscing
        elit. Tristique et egestas quis ipsum suspendisse ultrices gravida
        dictum. Quam pellentesque nec nam aliquam sem et tortor. Tincidunt arcu
        non sodales neque sodales ut etiam sit. Amet massa vitae tortor
        condimentum lacinia quis vel eros donec. In nisl nisi scelerisque eu
        ultrices vitae auctor eu. Feugiat sed lectus vestibulum mattis.
        Pellentesque pulvinar pellentesque habitant morbi tristique. Amet est
        placerat in egestas.
      </p>
      <br></br>
      <p>
        Viverra vitae congue eu consequat ac felis donec et odio. Diam
        sollicitudin tempor id eu nisl nunc mi ipsum faucibus. Hac habitasse
        platea dictumst vestibulum rhoncus est. Id nibh tortor id aliquet. Enim
        sed faucibus turpis in eu mi bibendum neque egestas. Vestibulum rhoncus
        est pellentesque elit ullamcorper dignissim cras tincidunt. Tincidunt
        arcu non sodales neque sodales ut etiam sit. Dignissim convallis aenean
        et tortor at risus viverra adipiscing at. Morbi tristique senectus et
        netus et malesuada fames ac. Ultrices mi tempus imperdiet nulla
        malesuada pellentesque. Hendrerit gravida rutrum quisque non. Mauris
        cursus mattis molestie a iaculis at erat pellentesque. Mi in nulla
        posuere sollicitudin aliquam ultrices sagittis orci a.
      </p>
      <br></br>
      <p>
        Nisl purus in mollis nunc. Diam ut venenatis tellus in. Risus at
        ultrices mi tempus. Integer feugiat scelerisque varius morbi enim nunc
        faucibus a pellentesque. At in tellus integer feugiat scelerisque. Eu
        lobortis elementum nibh tellus molestie nunc non blandit. Commodo
        ullamcorper a lacus vestibulum. Ut consequat semper viverra nam libero
        justo laoreet sit amet. Varius quam quisque id diam vel quam elementum
        pulvinar. Ut tristique et egestas quis ipsum suspendisse ultrices
        gravida. Aliquet eget sit amet tellus cras adipiscing enim eu turpis.
        Nec feugiat nisl pretium fusce id. Curabitur gravida arcu ac tortor
        dignissim convallis aenean et tortor. Turpis egestas pretium aenean
        pharetra magna ac. Ullamcorper a lacus vestibulum sed arcu non odio
        euismod lacinia. Blandit cursus risus at ultrices. In nulla posuere
        sollicitudin aliquam ultrices. Nascetur ridiculus mus mauris vitae
        ultricies leo. Rhoncus urna neque viverra justo nec. Pulvinar proin
        gravida hendrerit lectus a.
      </p>
    </div>
  );
};

export default MyExperience;
