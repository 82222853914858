import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Home from "./modules/home";
import MainNavigation from "./components/Navigation/MainNavigation";
import Footer from "./components/UIElements/footer";
// import Video from "./modules/videoIntroduction/video";
import MyExperience from "./modules/CVDisplay/MyExperience";
import DownloadCv from "./modules/DownloadCv/DownloadCv";
import DemoProjects from "./modules/personalProjects/DemoProjects";
import ChatBot from "./modules/chatbotLiveChat/ChatBot";
import ChatgGPT4 from "./modules/chatGPT4/gpt4";
import { ResumeProvider } from "./utils/ResumeContext";
import { visitorInfo } from "./utils/CaptureInfo";

const handleNavigation = (location) => {
  console.log("Navigated to:", location);
};

const App = () => {
  useEffect(() => {
    visitorInfo();
  }, []);

  return (
    <ResumeProvider>
      <Router onNavigation={handleNavigation}>
        <MainNavigation />

        <main>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/profile/experience" exact>
              <MyExperience />
            </Route>
            <Route path="/profile/demoprojects" exact>
              <DemoProjects />
            </Route>
            <Route path="/profile/chat" exact>
              <ChatBot />
            </Route>
            <Route path="/profile/cv/download" exact>
              <DownloadCv />
            </Route>
            <Route path="/admin/gpt4" exact>
              <ChatgGPT4 />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </main>
        <Footer />
      </Router>
    </ResumeProvider>
  );
};

export default App;
