import React, { useState, useContext } from "react";
import { ThemeContext } from "../../utils/ThemeContext";
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import MainHeader from "./MainHeader";
import SideDrawer from "./SideDrawer";
import Backdrop from "../UIElements/Backdrop";
import "./MainNavigation.css";

const MainNavigation = () => {
  const { theme } = useContext(ThemeContext);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}

      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavLinks />
        </nav>
      </SideDrawer>

      <MainHeader>
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span style={{ backgroundColor: theme.header.color }} />
          <span style={{ backgroundColor: theme.header.color }} />
          <span style={{ backgroundColor: theme.header.color }} />
        </button>
        <h1
          className="main-navigation__title"
          style={{
            "--link-color": theme.header.TitleColor,
          }}
        >
          <Link to="/" className="main-navigation__title-link">
            Nadeem Ahmed
          </Link>
        </h1>
        <nav className="main-navigation__header-nav">
          <NavLinks />
        </nav>
      </MainHeader>
    </React.Fragment>
  );
};

export default MainNavigation;
