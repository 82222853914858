// gpt4.js
import React, { useState, useEffect, useRef } from "react";
import DOMPurify from "dompurify";

import "./gpt4.css";
import { postData } from "../../APIs/BackendAPIs";

const ChatInterface = () => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      content: "Hi there, how may I assist you today?",
      sender: "assistant",
    },
  ]);
  const [newMessageText, setNewMessageText] = useState("");
  const chatHistoryRef = useRef(null); // Create a ref for the chat history container

  const handleSendMessage = async () => {
    if (!newMessageText.trim()) return;

    const userMessage = {
      id: Date.now(),
      content: newMessageText.trim(),
      sender: "user",
    };

    setMessages((currentMessages) => [...currentMessages, userMessage]);

    setNewMessageText("");

    try {
      const sanitizedMessage = DOMPurify.sanitize(newMessageText);
      const response = await postData("chat/admin/gpt4", {
        messages: messages.map((msg) => ({
          role: msg.sender === "user" ? "user" : "assistant",

          content: sanitizedMessage,
        })),
      });

      if (!response || !response.message) {
        throw new Error("Failed to fetch");
      }

      const newMessage = {
        id: Date.now() + 1,
        content: response.message.content,
        sender: "assistant",
      };
      setMessages((currentMessages) => [...currentMessages, newMessage]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (chatHistoryRef.current) {
        console.log(
          "Before scroll:",
          chatHistoryRef.current.scrollTop,
          "ScrollHeight:",
          chatHistoryRef.current.scrollHeight
        );
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        console.log("After scroll:", chatHistoryRef.current.scrollTop);
      }
    }, 100); // Adjust timeout as necessary; 100ms is just an example
  }, [messages]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const renderMessagePart = (part, index, isCodeBlock) => {
    if (isCodeBlock) {
      return (
        <pre key={`code-${index}`} className="code-block">
          {part}
        </pre>
      );
    } else {
      return part.split("\n").map((line, lineIndex) => (
        <React.Fragment key={`text-${index}-${lineIndex}`}>
          {line}
          <br />
        </React.Fragment>
      ));
    }
  };

  return (
    <div className="chat-interface">
      <div className="chat-history">
        {messages.map((message) => {
          const messageParts = message.content.split("```");
          return (
            <div key={message.id} className={`message ${message.sender}`}>
              {messageParts.map((part, index) => {
                const isCodeBlock = index % 2 !== 0;
                return renderMessagePart(part, index, isCodeBlock);
              })}
            </div>
          );
        })}
      </div>
      <div
        className="chat-input"
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          paddingBottom: "50px",
        }}
      >
        <textarea
          placeholder="Type your message..."
          value={newMessageText}
          onChange={(e) => setNewMessageText(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{ width: "100%", height: "50px" }} // Adjust styling as needed
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatInterface;
