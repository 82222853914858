import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { ThemeContext } from "../../utils/ThemeContext";
import "./NavLinks.css";
import liImage from "../../assets/images/li.png";

const NavLinks = (props) => {
  const { theme } = useContext(ThemeContext);

  const linkStyle = {
    color: theme.header.color,
    background: theme.header.background, // normal color
    padding: "0.5rem",
  };

  const activeLinkStyle = {
    color: theme.header.color,
    background: theme.header.LinkBackgroundColor, // active color
    border: "2px solid",
  };

  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact style={linkStyle} activeStyle={activeLinkStyle}>
          HOME
        </NavLink>
      </li>

      {/* <li>
        <NavLink
          to="/profile/experience"
          style={linkStyle}
          activeStyle={activeLinkStyle}
        >
          EXPERIENCE
        </NavLink>
      </li> */}

      {/* <li>
        <NavLink
          to="/profile/skills"
          style={linkStyle}
          activeStyle={activeLinkStyle}
        >
          SKILLS
        </NavLink>
      </li> */}

      {/* <li>
        <NavLink
          to="/profile/projects"
          style={linkStyle}
          activeStyle={activeLinkStyle}
        >
          PROJECTS
        </NavLink>
      </li> */}

      {/* <li>
        <NavLink
          to="/profile/demoprojects"
          style={linkStyle}
          activeStyle={activeLinkStyle}
        >
          DEMO PROJECTS
        </NavLink>
      </li> */}

      {/* <li>
        <NavLink
          to="/profile/chat"
          style={linkStyle}
          activeStyle={activeLinkStyle}
        >
          AI CHAT
        </NavLink>
      </li> */}

      <li>
        <a
          href="https://www.linkedin.com/in/nadeemahmedsyed"
          style={linkStyle}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={liImage}
            alt="LinkedIn"
            style={{ maxWidth: "20px", maxHeight: "20px" }}
          />
        </a>
      </li>
    </ul>
  );
};

export default NavLinks;
