// src/modules/home.js

import React, { useState, useEffect, useContext } from "react";
import ReactModal from "react-modal";
import "./home.css";
import { ThemeContext } from "../utils/ThemeContext";
import ChatBot from "./chatbotLiveChat/ChatBot";

ReactModal.setAppElement("#root"); // This helps with accessibility

const Home = () => {
  const { theme } = useContext(ThemeContext);
  const [isChatOpen, setIsChatOpen] = useState(false); // State to control the modal

  // Open/close chat
  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const [dynamicText, setDynamicText] = useState("Programmer");
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("down");
  const [currentResultIndex, setCurrentResultIndex] = useState(0);

  const results = [
    "Azure APIM",
    "Cloud to On-premises",
    "ML Ideations",
    "Data extraction",
    "Automation with Scripting",
  ];

  const resultColors = [
    "#3498db",
    "#e67e22",
    "#9b59b6",
    "#1abc9c",
    "#f39c12",
    "#e74c3c",
    "#2ecc71",
  ];

  useEffect(() => {
    const resultInterval = setInterval(() => {
      setCurrentResultIndex((prevIndex) => (prevIndex + 1) % results.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(resultInterval); // Cleanup interval on unmount
  }, [results.length]);

  // S3 image URLs
  const images = [
    "https://cogniverous-images.s3.us-east-1.amazonaws.com/m03.png",
    "https://cogniverous-images.s3.us-east-1.amazonaws.com/m01.png",
    "https://cogniverous-images.s3.us-east-1.amazonaws.com/m02.png",
    "https://cogniverous-images.s3.us-east-1.amazonaws.com/m04.png",
  ];

  useEffect(() => {
    const options = [
      "a developer",
      "an architect",
      "a creative thinker",
      "a continuous learner",
      "a problem solver",
      "detail oriented",
      "analytical",
      "innovative",
      "social :-)",
    ];
    let currentIndex = 0;

    const changeText = () => {
      const currentOption = options[currentIndex];
      setDynamicText(currentOption);
      currentIndex = (currentIndex + 1) % options.length;
    };

    const interval = setInterval(changeText, 1000);
    return () => clearInterval(interval);
  }, []);

  // Scroll event to update the current section index and scroll direction
  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const sections = document.querySelectorAll(".full-screen-section");
      const scrollPosition = window.scrollY + window.innerHeight / 2; // 50% of the viewport height
      const currentScrollTop = window.scrollY;

      // Determine scroll direction
      if (currentScrollTop > lastScrollTop) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Safari

      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        // Update the index only when the section takes up 50% or more of the viewport
        if (
          scrollPosition >= sectionTop &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          if (currentSectionIndex !== index) {
            setCurrentSectionIndex(index);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentSectionIndex]);

  const roles = [
    "🌩️ Cloud Engineering",
    "🌐 Web & API Development",
    "📊 Data Analytics",
    "🔄 ETL Operations",
    "🤖 Automation and Scripting",
    "💾 Database Designing",
    "🤖 Machine Learning",
  ];

  const colors = [
    "#3498db",
    "#e67e22",
    "#9b59b6",
    "#1abc9c",
    "#f39c12",
    "#e74c3c",
    "#2ecc71",
  ];

  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentRoleIndex((prevIndex) => (prevIndex + 1) % roles.length);
    }, 1500);

    return () => clearInterval(intervalId);
  }, [roles.length]);

  const scrollToNextSection = () => {
    const sections = document.querySelectorAll(".full-screen-section");

    // Calculate next section index directly based on the current index
    const nextSectionIndex = (currentSectionIndex + 1) % sections.length;
    const nextSection = sections[nextSectionIndex];

    // Scroll smoothly to the next section and update the index
    if (nextSection) {
      window.scrollTo({
        top: nextSection.offsetTop,
        behavior: "smooth",
      });
      setCurrentSectionIndex(nextSectionIndex);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentSectionIndex(0);
  };

  return (
    <>
      <section
        className="full-screen-section intro"
        style={{ backgroundColor: theme.li.background }}
      >
        <div className="half-width-container">
          <h1 className="large-font" style={{ color: theme.header.TitleColor }}>
            I'm...
          </h1>
          <h1
            className="large-font dynamic-font"
            style={{ color: theme.dynamicfont.color }}
          >
            {dynamicText}
          </h1>
          <h1 className="large-font" style={{ color: theme.header.TitleColor }}>
            I'm Nadeem!
          </h1>
        </div>
        <div className="right-half">
          <div className="image-container">
            <img
              src={images[0]} // Image specific to Section 1
              alt="Section"
              className={`section-image ${
                currentSectionIndex === 0 ? "visible" : ""
              } ${scrollDirection}`}
            />
          </div>
        </div>
      </section>

      <section
        className="full-screen-section b1"
        style={{ backgroundColor: theme.li.background1, color: theme.li.color }}
      >
        <div className="contentsection">
          <h1>ask me about...</h1>
          <p>
            <span
              className="highlighted-role"
              style={{
                backgroundColor: colors[currentRoleIndex],
                color: "#fff",
              }}
            >
              {roles[currentRoleIndex]}
            </span>
          </p>
        </div>
        <div className="right-half">
          <div className="image-container">
            <img
              src={images[1]} // Image specific to Section 2
              alt="Section"
              className={`section-image ${
                currentSectionIndex === 1 ? "visible" : ""
              } ${scrollDirection}`}
            />
          </div>
        </div>
      </section>

      <section
        className="full-screen-section b2"
        style={{ backgroundColor: theme.li.background2, color: theme.li.color }}
      >
        <div className="contentsection">
          <h1>results delivered...</h1>
          <p>
            <span
              className="delivered-item"
              style={{
                backgroundColor:
                  resultColors[currentResultIndex % resultColors.length],
                color: "#fff",
              }}
            >
              {results[currentResultIndex]}
            </span>
          </p>
        </div>
        <div className="right-half">
          <div className="image-container">
            <img
              src={images[2]} // Image specific to Section 3
              alt="Section"
              className={`section-image ${
                currentSectionIndex === 2 ? "visible" : ""
              } ${scrollDirection}`}
            />
          </div>
        </div>
      </section>

      <section
        className="full-screen-section b3"
        style={{ backgroundColor: theme.li.background3, color: theme.li.color }}
      >
        <div className="contentsection">
          <h1>my expertise</h1>
          <p>
            As a Systems and Software Engineer, I specialize in DevOps, AI/ML,
            and full-stack development with Python, JavaScript, React, and
            Node.js. I manage and optimize Azure and AWS cloud infrastructures
            and have expertise in API management, databases (MongoDB, MySQL),
            and tools like Git, Jenkins, and Jupyter Notebook. I’m committed to
            continuous learning, especially in AI, and excel in fast-paced,
            tech-driven environments.
          </p>
        </div>
        <div className="right-half">
          <div className="image-container">
            <img
              src={images[3]} // Image specific to Section 4
              alt="Section"
              className={`section-image ${
                currentSectionIndex === 3 ? "visible" : ""
              } ${scrollDirection}`}
            />
          </div>
        </div>
      </section>

      <button onClick={scrollToNextSection} className="scroll-down-button">
        <i className="fa fa-arrow-down"></i>
      </button>
      {currentSectionIndex > 0 && (
        <button onClick={scrollToTop} className="scroll-up-button">
          <i className="fa fa-arrow-up"></i>
        </button>
      )}

      <button
        onClick={toggleChat}
        className="open-chat-button"
        style={{ backgroundColor: theme.chatButtonColor }}
      >
        Ask AI...
      </button>

      {/* Modal for ChatBot */}
      <ReactModal
        isOpen={isChatOpen}
        onRequestClose={toggleChat}
        contentLabel="ChatBot"
        style={{
          content: {
            backgroundColor: theme.chatboxBackground,
          },
        }}
        className="chatbot-modal"
        overlayClassName="chatbot-modal-overlay"
      >
        <button onClick={toggleChat} className="close-chat-button">
          X
        </button>
        <ChatBot /> {/* Render the ChatBot component inside the modal */}
      </ReactModal>
    </>
  );
};

export default Home;
